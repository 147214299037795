import styled from "styled-components"

let S = {}
S.Container = styled.div`
  margin-top: 15px;
  padding-left: 7.5px;

  & > a {
    display: flex;
    align-items: center;

    & > span {
      padding-left: 7.5px;
    }
  }
`

export default S

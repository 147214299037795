import React from "react"
import { Link } from "gatsby"
import S from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons"

const Back = () => {
  return (
    <S.Container>
      <Link to="/">
        <FontAwesomeIcon icon={faChevronLeft} size="lg" />
        <span>Back to Courses</span>
      </Link>
    </S.Container>
  )
}

export default Back

import React, { useState, useEffect, Fragment } from "react"
import { Router } from "@reach/router"
import useFetch from "../hooks/useFetch"
import { API_URL } from "../utils"
import Helmet from "../components/Helmet"
import Back from "../pagesCourse/Back"
import Content from "../pagesCourse/Content"
import Hdr from "../components/Hdr"
import Ftr from "../components/Ftr"

const CoursePage = () => {
  return (
    <Router>
      <Component path="/course/:courseId" />
    </Router>
  )
}

export default CoursePage

const Component = props => {
  const [url, setUrl] = useState(null)
  const [course, setCourse] = useState({})

  const { loading, data } = useFetch(url)

  useEffect(() => {
    const newUrl = new URL(`${API_URL}/coursesInit`)
    const params = {
      courseId: props.courseId,
    }
    newUrl.search = new URLSearchParams(params)
    setUrl(newUrl)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (data && data.resp === 1) {
      setCourse({ ...data.courses[0] })
    }
  }, [data])

  return (
    <Fragment>
      <Helmet
        title="courseInit"
        descr="The Palm Beach County Bar Association (PBCBA) offers CLE hours from the sale of audio MP3’s, MP4’s and CD’s recorded at previously held live seminars of area bar associations."
      />
      <Hdr />
      <Back />
      {loading ? (
        <div>Loading...</div>
      ) : data.courses.length > 0 ? (
        <Content item={course} />
      ) : (
        <div>Course not found</div>
      )}
      <Ftr />
    </Fragment>
  )
}
